<template>
  <Component
    :is="wrapperType"
    :class="badgeClasses"
    :to="href"
  >
    <slot name="icon" />
    <slot name="default" />
    <Component :is="props.icon" />
    <span
      v-if="removable"
      class="-mr-1 ml-1 cursor-pointer"
      @click="$emit('remove')"
    >
      <BaseIcon icon="fal fa-xmark" />
    </span>
  </Component>
</template>

<script lang="ts" setup>
import type { BadgeSize, BadgeType } from '@/types/badges'
import { useBadgeClasses } from '#imports'

interface Props {
  type?: BadgeType
  size?: BadgeSize
  href?: string
  icon?: string
  fakeLink?: boolean
  removable?: boolean
  loading?: boolean
  truncate?: boolean
}

defineEmits<{
  (e: 'remove'): void
}>()
const props = withDefaults(defineProps<Props>(), {
  type: 'default',
  size: 'xs',
  href: '',
  icon: undefined,
  removable: false,
  truncate: false,
})

const slots = useSlots()
const isContentEmpty = computed(() => !slots.default)
const wrapperType = computed(() => (props.href ? 'NuxtLink' : 'span'))
const { badgeClasses } = useBadgeClasses(toRefs(props), { isContentEmpty })
</script>
