import { clsx } from 'clsx/lite'
import type { BadgeCarrierType, BadgeSize, BadgeType } from '@/types/badges'

const defaultBadgeClasses = clsx('whitespace-nowrap rounded px-2.5 py-0.5 font-medium')
const onlyIconClasses = clsx(
  'inline-flex items-center justify-center rounded-full p-1 font-semibold leading-none',
)

const badgeCarrierClasses: Record<BadgeCarrierType, string> = {
  balikovna: clsx('bg-yellow-200 text-gray-900'),
  dpd: clsx('bg-red-700 text-white'),
  gls: clsx('bg-blue-700 text-amber-400'),
  ppl: clsx('bg-neutral-50 text-blue-700'),
  liftago: clsx('bg-[#0070ce] text-white'),
  fedex: clsx('bg-purple-700 text-white'),
  tnt: clsx('bg-orange-500 text-white'),
  toptrans: clsx('bg-sky-700 text-white'),
  ups: clsx('bg-amber-400 text-gray-900'),
  wedo: clsx('bg-emerald-400 text-gray-900'),
  correos: clsx('bg-indigo-900 text-white'),
  inpost: clsx('bg-yellow-200 text-gray-900'),
  seur: clsx('bg-red-700 text-white'),
  zasilkovna: clsx('bg-red-700 text-white'),
}

const badgeBaseClasses: Record<BadgeType, string> = {
  default: clsx('bg-primary-100 text-primary-800 dark:bg-primary-900 dark:text-primary-300'),
  dark: clsx('bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'),
  'dark-alt': clsx('bg-gray-200 text-gray-500 dark:bg-gray-700 dark:text-gray-300'),
  red: clsx('bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'),
  green: clsx('bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'),
  yellow: clsx('bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'),
  indigo: clsx('bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300'),
  purple: clsx('bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300'),
  pink: clsx('bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300'),
  ...badgeCarrierClasses,
}

const badgeHoverState = 'cursor-pointer'

const badgeLinkClasses: Record<BadgeType, string> = {
  default: clsx('hover:bg-primary-200 dark:hover:bg-primary-800'),
  dark: clsx('hover:bg-gray-200 dark:hover:bg-gray-600'),
  'dark-alt': clsx('hover:bg-gray-300 dark:hover:bg-gray-600'),
  red: clsx('hover:bg-red-200 dark:hover:bg-red-800'),
  green: clsx('hover:bg-green-200 dark:hover:bg-green-800'),
  yellow: clsx('hover:bg-yellow-200 dark:hover:bg-yellow-800'),
  indigo: clsx('hover:bg-indigo-200 dark:hover:bg-indigo-800'),
  purple: clsx('hover:bg-purple-200 dark:hover:bg-purple-800'),
  pink: clsx('hover:bg-pink-200 dark:hover:bg-pink-800'),
  ...badgeCarrierClasses,
}

const badgeSizeClasses: Record<BadgeSize, string> = {
  xxs: 'text-xs',
  xs: 'text-xs',
  sm: 'text-sm',
}

export interface UseBadgeClassesProps {
  type: Ref<BadgeType>
  size: Ref<BadgeSize>
  href: Ref<string>
  fakeLink: Ref<boolean>
  loading: Ref<boolean>
  truncate: Ref<boolean>
}
export interface UseBadgeClassesOptions {
  isContentEmpty: Ref<boolean>
}

export function useBadgeClasses(
  props: UseBadgeClassesProps,
  options: UseBadgeClassesOptions,
): {
    badgeClasses: Ref<string>
  } {
  const badgeClasses = computed<string>(() =>
    clsx(
      badgeSizeClasses[props.size.value],
      badgeBaseClasses[props.type.value],
      props.loading.value ? 'animate-pulse text-transparent' : undefined,
      props.href.value || props.fakeLink.value ? [badgeLinkClasses[props.type.value], badgeHoverState] : '',
      options.isContentEmpty.value ? onlyIconClasses : defaultBadgeClasses,
      props.size.value === 'xxs' ? 'h-4 min-h-4' : 'min-h-6 min-w-6',
      props.truncate.value && 'inline-block max-w-full truncate',
    ),
  )
  return {
    badgeClasses,
  }
}
